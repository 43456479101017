<template>
  <div>
    <div class="detail-photos">
      <div class="detail-photo-title">Shared files</div>
      <div class="detail-photo-grid">
        <div
          v-for="(item, index) in setFiles"
          :key="index"
          class="cursor-pointer"
          @click="indexImage = index"
        >
          <img :src="item" alt="" />
        </div>
      </div>
    </div>
    <cool-light-box
      :items="setFiles"
      :index="indexImage"
      @close="indexImage = null"
    />
  </div>
</template>
<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
export default {
  components: {
    CoolLightBox,
  },
  props: {
    files: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      indexImage: null,
    };
  },
  computed: {
    setFiles() {
      let array = [];
      this.files.forEach((item) => {
        if (item.message_type == "image") {
          array.push(item.message_attachment);
        }
      });
      return array;
    },
  },
};
</script>