<template>
  <div>
    <div class="chat-area-main">
      <b-container v-for="(item, i) in contentMessage" :key="i">
        <div class="text-center">
          <p>{{ item.date }}</p>
        </div>
        <div
          class="chat-msg"
          v-for="(hour, ih) in item.hours"
          :key="ih"
          :class="!hour.is_sender ? 'owner' : ''"
        >
          <div class="chat-msg-profile">
            <img
              class="chat-msg-img"
              :src="
                hour.is_sender
                  ? `/assets/images/icons/user-app${isDarkSkin ? '-w' : ''}.png`
                  : '/assets/images/modules-icons/creditexperts.png'
              "
              alt=""
            />
            <div class="chat-msg-date">{{ hour.hour }}</div>
          </div>
          <div class="chat-msg-content">
            <div
              class="chat-msg-text"
              v-for="(data, id) in hour.data"
              :key="id"
            >
              <img
                v-if="data.message_type == 'image'"
                class="cursor-pointer"
                :src="
                  data.image_attachment
                    ? data.image_attachment
                    : data.message_attachment
                "
                @click="
                  itemImage = [
                    data.image_attachment
                      ? data.image_attachment
                      : data.message_attachment,
                  ];
                  indexImage = 0;
                "
              />
              <span
                v-else-if="data.message_type == 'file'"
                class="cursor-pointer"
                @click="windows.open()"
              >
                <b-row>
                  <b-col>
                    <img
                      src="/assets/images/extensions/paper.png"
                      class="image-icon-file"
                    />
                  </b-col>
                  <b-col class="center-content" style="margin-left: -15px">
                    <a :href="data.message_attachment" download>{{
                      fileName(
                        data.message_attachment_name,
                        data.message_attachment_type
                      )
                    }}</a>
                  </b-col>
                </b-row>
              </span>
              <div
                :class="data.message_type != 'text' ? 'message-file' : ''"
                id="content-message"
              >
                {{ data.content }}
              </div>
            </div>
          </div>
        </div>
      </b-container>
    </div>
    <!-- && client.current_advisor == currentUser.user_id -->
    <div class="chat-area-footer" v-if="!closeChatVar">
      <div class="file-selected-chat" v-if="nameFileData">
        {{ nameFileData }}
        <feather-icon
          icon="XCircleIcon"
          style="color: #f75f5f"
          @click="clearFile"
        />
      </div>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-paperclip"
        @click="$refs.inputFileChat.click()"
      >
        <path
          d="M21.44 11.05l-9.19 9.19a6 6 0 01-8.49-8.49l9.19-9.19a4 4 0 015.66 5.66l-9.2 9.19a2 2 0 01-2.83-2.83l8.49-8.48"
        />
      </svg>

      <input type="file" ref="inputFileChat" @change="fileToBase64" hidden />
      <form @submit.prevent="sendMessage">
        <input
          type="search"
          placeholder="Type something here..."
          v-model="message"
        />
      </form>

      <svg
        v-if="(message != null && message != '') || nameFileData != null"
        class="feather feather-paperclip"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 60.064 60.064"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        @click="sendMessage"
      >
        <path
          d="M59.84,7.897c-0.218-0.268-0.556-0.393-0.893-0.353c-0.077,0.004-0.149,0.017-0.224,0.039L0.738,23.354
	C0.312,23.47,0.012,23.852,0,24.293c-0.011,0.441,0.269,0.838,0.688,0.976l21.217,6.952l-1.898,15.182
	c-0.05,0.4,0.145,0.791,0.494,0.991c0.155,0.089,0.327,0.133,0.498,0.133c0.215,0,0.43-0.069,0.608-0.206l7.765-5.946l6.807,9.725
	c0.188,0.269,0.494,0.427,0.819,0.427c0.022,0,0.045-0.001,0.068-0.002c0.35-0.024,0.661-0.229,0.821-0.542l22.063-43
	C60.134,8.631,60.09,8.205,59.84,7.897z M52.895,11.241L22.861,30.429L4.484,24.408L52.895,11.241z M22.288,45.281l1.382-11.053
	l4.555,6.507L22.288,45.281z M36.868,49.594L24.418,31.808l32.1-20.508L36.868,49.594z"
        />
      </svg>
      <svg
        v-else
        v-b-tooltip.hover="'close chat'"
        class="feather feather-paperclip"
        width="50px"
        height="50px"
        viewBox="0 0 50 50"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
        @click="closeChat()"
      >
        <path d="M37.304 11.282l1.414 1.414-26.022 26.02-1.414-1.413z" />
        <path d="M12.696 11.282l26.022 26.02-1.414 1.415-26.022-26.02z" />
      </svg>
    </div>
    <cool-light-box
      :items="itemImage"
      :index="indexImage"
      @close="indexImage = null"
    />
    <upload-files
      v-if="modalUploadFiles"
      :modal="modalUploadFiles"
    ></upload-files>
  </div>
</template>
<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
import UploadFiles from "./UploadFiles.vue";
import chatService from "@/views/ce-digital/sub-modules/customer-service/views/chat-app/services/chat-app.service";
import { mapGetters } from "vuex";
import moment from "moment";
import mixin from "@/views/ce-digital/sub-modules/customer-service/views/chat-app/mixin.js";

export default {
  mixins: [mixin],
  components: {
    CoolLightBox,
    UploadFiles,
  },
  props: {
    messages: {
      default: [],
      type: Array,
    },
    content: {
      default: [],
      type: Array,
    },
    client: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      itemImage: [],
      countImage: 0,
      indexImage: null,
      fileData: null,
      modalUploadFiles: false,
      nameFileData: null,
      sizeFile: null,
      fileId: 0,
      message: null,
      messageType: null,
      contentMessage: this.content,
      closeChatVar: false,
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },

  created() {
    // console.log("sasas", this.messages);
  },

  methods: {
    fileName(name, extension) {
      if (name.length > 12) {
        return name.slice(0, 9) + "..." + extension;
      }
      return name;
    },

    fileToBase64(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file.target.files[0]);
      var vm = this;
      reader.onload = function (e) {
        vm.fileData = reader.result;
        vm.fileData = vm.fileData.split(",")[1];
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
      this.nameFileData = file.target.files[0].name;
      this.sizeFile = file.target.files[0].size / 1024 / 1024;
    },

    clearFile() {
      this.fileData = null;
      this.nameFileData = null;
      this.sizeFile = null;
      this.fileId += 1;
    },

    async sendMessage() {
      this.addPreloader();
      let extension;
      let size;

      this.nameFileData != null
        ? (extension = this.nameFileData.split(".").pop())
        : (extension = null);

      this.sizeFile != null ? (size = this.sizeFile) : (size = null);

      this.messageType =
        extension === null ? "text" : this.validateIsImage(this.nameFileData);

      let date_text = moment().format("MM/DD/YYYY");

      let hour_text = new Date().toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "America/Los_Angeles",
      });

      let newMessage = {
        user: this.client.user,
        name_user: this.client.name_user,
        content: this.message ?? "",
        advisor_id: this.currentUser.user_id,
        is_sender: false,
        message_type: this.messageType,
        indexImage: this.messageType === "image" ? this.countImage : null,
        message_attachment: this.fileData,
        message_attachment_type: extension,
        message_attachment_name: this.nameFileData,
        image_attachment: `data:image/${extension};base64, ` + this.fileData,
        message_attachment_size:
          this.nameFileData != null ? Math.round(size).toString() : null,
        created_at: date_text + " " + hour_text,
        hour: moment(date_text + " " + hour_text).format("H:mm"),
        date: moment(date_text + " " + hour_text).format("MM/DD/YYYY"),
      };

      if (this.messageType === "image") {
        var { image_attachment, message_attachment } = newMessage;
        this.addImage(image_attachment ? image_attachment : message_attachment);
        this.countImage++;
      }

      await chatService.sendMessage(newMessage);

      this.messages.push(newMessage);
      let messages = [...this.messages];

      this.contentMessage = this.groupByDate(messages);
      this.clearContent();
      this.removePreloader();
    },
    receiveMessage(
      content,
      advisor_id,
      user,
      message_type,
      message_attachment,
      message_attachment_name,
      message_attachment_type
    ) {
      if (String(user).toUpperCase() == String(this.idClient).toUpperCase()) {
        //change viewed await 2 seconds
        // setTimeout(() => {
        //   axios
        //     .post(this.apiApp + "/message/update-viewed", {
        //       user,
        //       advisor_id,
        //     })
        //     .then((response) => {
        //       eventBus.$emit("app-general-counter", true);
        //     });
        // }, 2000);
        var date_text = moment().format("MM/DD/YYYY");
        var hour_text = new Date().toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "America/Los_Angeles",
        });
        var new_id = this.listMessage.length + 1;
        var newMessage = {
          id: new_id,
          user: user,
          name_user: this.clientName,
          content: content,
          advisor_id: advisor_id, // this.currentuser.id,
          is_sender: true,
          message_type: message_type,
          indexImage: message_type === "image" ? this.countImage : null,
          message_attachment: message_attachment,
          message_attachment_type: message_attachment_type,
          message_attachment_name: message_attachment_name,
          created_at: date_text + " " + hour_text,
          hour: moment(date_text + " " + hour_text).format("H:mm"),
          date: moment(date_text + " " + hour_text).format("MM/DD/YYYY"),
        };
        if (newMessage.message_type === "image") {
          this.addImage(message_attachment);
          this.countImage++;
        }
        this.messages.push(newMessage);
        let messages = [...this.messages];

        this.contentMessage = this.groupByDate(messages);
        this.clearContent();
      } else {
        // this.messages.map((client) => {
        //   if (client.user === user) {
        //     client.counter += 1;
        //   }
        // });
      }
    },

    addImage(image) {
      this.itemImage.push(image);
    },

    clearContent() {
      this.message = null;
      this.clearFile();
    },

    validateIsImage(nameFile) {
      var extension = nameFile.split(".").pop();
      if (extension == "png" || extension == "jpg" || extension == "jpeg") {
        return "image";
      } else {
        return "file";
      }
    },

    async closeChat() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.sendCloseChat();
        }
      });
    },

    async sendCloseChat() {
      this.addPreloader();
      const params = {
        account: this.client.user,
        user_id: this.currentUser.user_id,
        if_generate_commission: 1,
      };
      await chatService.closeChat(params);
      this.closeChatVar = true;
      this.removePreloader();
    },
  },
};
</script>
<style>
.file-selected-chat {
  position: absolute;
  top: -23px;
}
.message-file {
  margin-top: 6px;
  margin-bottom: -6px;
}
.chat-msg-text {
  max-width: 300px;
}
</style>