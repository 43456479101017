import { amgApiApp } from "@/service/axios"

class chatAppService {
	async getUsersApp(params) {
		const { data } = await amgApiApp.post("/message/get-all-clients", params)
		return data
	}
	async getMessages(params) {
		const { data } = await amgApiApp.post(
		  "/message/get-client-messages",
		  params
		);
		return data;
	}

	async sendMessage(params) {
		const { data } = await amgApiApp.post(
			"/message/send-message-to-client",
			params
		)
		return data
	}

	async closeChat(params) {
		await amgApiApp.post("/message/close-chat", params)
	}

	async acceptRequestAdvisor(params) {
		const { data } = await amgApiApp.post(
			"/round/accept-request-advisor",
			params
		)
		return data
	}
}

export default new chatAppService()
