<template>
  <b-modal
    v-model="modal"
    title="UPLOAD FILES"
    size="lg"
    title-class="h3 text-white"
    hide-footer
    modal-class="modal-primary"
    id="modalPayStup"
  >
    <b-container>
      <h1>sss</h1>
    </b-container>
  </b-modal>
</template>
<script>
export default {
  props: {
    modal: {
      default: false,
      type: Boolean,
    },
  },
};
</script>