export default {
  methods: {
    groupByDate(data) {
      let dates = []
      data.forEach((element) => {
        let exists = false
        dates.forEach((date) => {
          if (element.date == date.date) {
            exists = true
          }
        })
        if (!exists) dates.push({ date: element.date, hours: [] })
      })
      return this.groupByHour(data, dates)
    },

    groupByHour(data, dates) {
      let messages = [...data]
      dates.forEach((element) => {
        messages.forEach((message) => {
          if (element.date == message.date) {
            element.hours.push({
              hour: message.hour,
              is_sender: message.is_sender,
              data: [message],
            })
          }
        })
      })
      dates = this.groupByRepeat(dates)
      return dates
    },

    groupByRepeat(dates) {
      dates.forEach((element) => {
        element.hours.forEach((item, index) => {
          item.repeat = false
          if (index > 0) {
            if (
              item.hour == element.hours[index - 1].hour &&
              item.is_sender == element.hours[index - 1].is_sender
            ) {
              let dataTem = []
              element.hours[index - 1].data.forEach((data) => {
                dataTem.push(data)
              })
              dataTem.push(item.data[0])
              item.data = dataTem
              element.hours[index - 1].repeat = true
            }
          }
        })
        element.hours = element.hours.filter(function (item) {
          return !item.repeat
        })
      })
      return dates
    },
  },
}
