<template>
  <div>
    <cool-light-box
      :items="setFiles"
      :index="indexImage"
      @close="indexImage = null"
    />
    <b-card no-body style="border-radius: 25px">
      <div
        class="head"
        :class="{ 'head--2col': !showRightBar || !isSelectClient }"
      >
        <div
          class="head__box"
          :class="!isDarkSkin ? 'light' : ''"
          style="border-left: 0px"
        >
          <b-button
            class="btn-icon rounded-circle ml-1"
            variant="secondary"
            size="md"
          >
            <feather-icon icon="CpuIcon" size="20" />
          </b-button>
          <div>
            <span style="font-size: 1.2rem">AMG Technical Agent</span>
          </div>
        </div>
        <div class="head__box" :class="!isDarkSkin ? 'light' : ''">
          <b-button
            class="btn-icon rounded-circle ml-1"
            variant="secondary"
            size="md"
            v-if="isSelectClient"
          >
            <feather-icon icon="UserIcon" size="20" />
          </b-button>
          <div style="flex-grow: 1">
            <span style="position: relative; font-size: 1.2rem"
              >{{ client.name || "" }}
            </span>

            <br />
            <span style="font-size: 0.9rem; color: gray" v-if="isSelectClient"
              >Account: {{ client.user || "" }}</span
            >
          </div>
          <b-button
            class="p-0"
            variant="flat-secondary"
            v-b-tooltip.bottom="'Toogle Files'"
            @click="toogleRightBar"
          >
            <feather-icon class="text-secondary" icon="MenuIcon" size="20" />
          </b-button>
        </div>
        <div
          class="head__box"
          :class="!isDarkSkin ? 'light' : ''"
          v-if="showRightBar && isSelectClient"
        ></div>
      </div>
      <div
        class="body"
        :class="{ 'body--2col': !showRightBar || !isSelectClient }"
      >
        <div
          class="body__box body__users m-0"
          :class="!isDarkSkin ? 'light' : ''"
          style="border-left: 0px"
        >
          <div
            class="clickable hover-user px-1"
            v-for="user in listUsers"
            :key="user.user"
            @click="setClient(user)"
            :class="{
              'user--active': user.user === client.user,
              light: !isDarkSkin,
            }"
          >
            <div class="user">
              <b-button
                class="btn-icon rounded-circle my-1"
                variant="secondary"
                size="md"
              >
                <feather-icon icon="UserIcon" size="20" />
              </b-button>
              <div class="w-100 my-1">
                <b-row>
                  <b-col cols="10">
                    <span style="font-size: 1.1rem; font-weight: 600">
                      {{ user.name }}
                    </span>
                    <br />
                    <span>
                      {{ user.content !== "" ? user.content : "Attachment" }}
                    </span>
                  </b-col>
                  <b-col cols="2" class="text-right">
                    <b-badge
                      pill
                      variant="danger"
                      class="padding-pill"
                      v-if="user.counter > 0"
                    >
                      {{ user.counter > 99 ? "99+" : user.counter }}
                    </b-badge>
                  </b-col>
                </b-row>

                <div class="text-right" style="font-size: 0.8rem">
                  {{ user.created_at | myGlobalDay }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="body__box" :class="!isDarkSkin ? 'light' : ''">
          <div class="body__chat">
            <div v-if="!isSelectClient">
              <center>
                <b-img
                  src="https://i.pinimg.com/originals/db/9c/64/db9c64cbcef7fdd64691c0f200f3ce8d.png"
                  fluid
                  style="width: 40%"
                />
                <h3 class="text-muted">No client selected</h3>
              </center>
            </div>
            <div class="body__messages" id="chat">
              <template v-for="(message, index) in messages">
                <div class="message" :key="index" v-if="message.is_sender">
                  <b-avatar
                    variant="secondary"
                    style="background-color: #82868b"
                    size="md"
                  />

                  <div class="message__content">
                    {{ message.name_user }}
                    <p class="message__text message__text--user my-0">
                      <img
                        class="file"
                        :src="message.message_attachment"
                        v-if="message.message_attachment"
                      />
                      {{ message.content }}
                    </p>
                    <span class="message__time">{{
                      message.datetime | myGlobalDay
                    }}</span>
                  </div>
                </div>
                <div class="message message--mine" :key="index" v-else>
                  <b-avatar size="md">
                    <feather-icon icon="UserIcon" size="20" />
                  </b-avatar>
                  <div class="message__content message__content--mine">
                    Tech Agent
                    <p class="message__text message__text--mine my-0">
                      <img
                        class="file"
                        :src="message.message_attachment"
                        v-if="message.message_attachment"
                      />
                      {{ message.content }}
                    </p>
                    <span class="message__time">{{
                      message.datetime | myGlobalDay
                    }}</span>
                  </div>
                </div>
              </template>
            </div>
            <div
              class="body__input"
              v-if="client.current_advisor == currentUser.user_id"
            >
              <!-- v-if="
                !closeChatVar && client.current_advisor == currentUser.user_id
              " -->
              <b-button class="btn-icon rounded-circle" style="width: 4rem">
                <feather-icon icon="PaperclipIcon" size="20" />
              </b-button>
              <div class="input">
                <input
                  class="input__field"
                  type="text"
                  placeholder="Type message here..."
                  v-model="message"
                  @keyup.enter="sendMessage"
                  :disabled="!isSelectClient"
                />
                <b-button
                  class="btn-icon rounded-circle"
                  variant="primary"
                  size="md"
                  @click="sendMessage"
                >
                  <feather-icon icon="SendIcon" size="18" />
                </b-button>
                <b-button
                  class="btn-icon rounded-circle ml-1"
                  variant="secondary"
                  size="md"
                  @click="closeChat"
                >
                  <feather-icon icon="XIcon" size="18" />
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="body__box"
          :class="!isDarkSkin ? 'light' : ''"
          v-if="showRightBar && isSelectClient"
        >
          <span style="display: block; margin-bottom: 1rem; font-size: 1.2rem"
            >Shared Files
            <span style="font-size: 1rem; color: gray"
              >({{ files.length || 0 }} )</span
            ></span
          >
          <div class="body__files">
            <img
              class="file clickable"
              :src="file"
              v-for="(file, index) in setFiles"
              :key="index"
              @click="indexImage = index"
            />
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ChatService from "@/views/ce-digital/sub-modules/customer-service/views/chat-app/services/chat-app.service"
import CoolLightBox from "vue-cool-lightbox"
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css"
import moment from "moment"
import mixin from "@/views/ce-digital/sub-modules/customer-service/views/chat-app/mixin.js"
import UploadFiles from "./components/UploadFiles.vue"

import headerChat from "./components/Header.vue"
import usersChat from "./components/Users.vue"
import contentChat from "./components/Content.vue"
import contentFiles from "./components/Files.vue"
import { mapGetters } from "vuex"
export default {
  mixins: [mixin],
  components: {
    headerChat,
    usersChat,
    contentChat,
    contentFiles,
    CoolLightBox,
    UploadFiles,
  },
  data() {
    return {
      isSelectClient: false,
      messages: [],
      keyMessages: 0,
      client: {},
      files: [],
      content: [],
      // ----
      showRightBar: true,
      users: [],
      indexImage: null,
      closeChatVar: false,
      message: "",
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      show: "CeDigitalCsAlerts/getAlertChat",
    }),
    setFiles() {
      let array = []
      this.files.forEach((item) => {
        if (item.message_type == "image") {
          array.push(item.message_attachment)
        }
      })
      return array
    },
    listUsers() {
      return this.users.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at)
      })
    },
  },
  mounted() {
    window.socket.unsubscribe("channel-chat")
    window.socket.subscribe("channel-chat")
    window.socket.bind("client-messaging", (val) => {
      this.getUsers()
      this.receiveMessage(
        val.content,
        val.advisor_id,
        val.user,
        val.message_type,
        val.message_attachment,
        val.message_attachment_name,
        val.message_attachment_type
      )
    })
  },
  methods: {
    setMessages(messages, content, files, client) {
      this.messages = messages
      this.content = content
      this.files = files
      this.client = client
      this.keyMessages += 1
    },
    setClient(client) {
      this.isSelectClient = true
      const { user, name_user, current_advisor } = client
      this.client = {
        user: user,
        name: name_user,
        current_advisor: current_advisor,
      }
      client.counter = 0
      this.getClientMessages(user)
    },
    async getClientMessages(user) {
      const params = {
        advisor_id: null,
        user,
      }
      const data = await ChatService.getMessages(params)

      this.messages = data.messages
      this.getFiles(data.messages)

      this.$nextTick(function () {
        this.scrollToEnd()
      })
    },
    async getUsers() {
      this.addPreloader()
      try {
        let body = { advisor_id: null }
        let res = await ChatService.getUsersApp(body)
        let users = []
        res.map((user) => {
          let name =
            user.name_user.length > 25
              ? user.name_user.slice(0, 25) + "..."
              : user.name_user
          users.push({
            ...user,
            name: name,
          })
        })
        this.users = users
      } catch (e) {
        console.log(e)
      }
      this.removePreloader()
    },
    async getFiles(messages) {
      try {
        let files = messages.filter((msg) => msg.message_type != "text")
        this.files = files
      } catch (e) {
        console.log(e)
      }
    },
    async closeChat() {
      const response = await this.showConfirmSwal()
      if (response.isConfirmed) {
        this.sendCloseChat()
      }
    },

    async sendCloseChat() {
      this.addPreloader()
      const params = {
        account: this.client.user,
        user_id: this.currentUser.user_id,
        if_generate_commission: 1,
      }
      await ChatService.closeChat(params)
      this.closeChatVar = true
      this.removePreloader()
    },

    fileToBase64(file) {
      var reader = new FileReader()
      reader.readAsDataURL(file.target.files[0])
      var vm = this
      reader.onload = function (e) {
        vm.fileData = reader.result
        vm.fileData = vm.fileData.split(",")[1]
      }
      reader.onerror = function (error) {
        console.log("Error: ", error)
      }
      this.nameFileData = file.target.files[0].name
      this.sizeFile = file.target.files[0].size / 1024 / 1024
    },

    clearFile() {
      this.fileData = null
      this.nameFileData = null
      this.sizeFile = null
      this.fileId += 1
    },

    async sendMessage() {
      this.addPreloader()
      let extension
      let size

      this.nameFileData != null
        ? (extension = this.nameFileData.split(".").pop())
        : (extension = null)

      this.sizeFile != null ? (size = this.sizeFile) : (size = null)

      this.messageType =
        extension === null ? "text" : this.validateIsImage(this.nameFileData)

      let date_text = moment().format("MM/DD/YYYY")

      let hour_text = new Date().toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "America/Los_Angeles",
      })

      let newMessage = {
        user: this.client.user,
        name_user: this.client.name_user,
        content: this.message ?? "",
        advisor_id: this.currentUser.user_id,
        is_sender: false,
        message_type: this.messageType,
        indexImage: this.messageType === "image" ? this.countImage : null,
        message_attachment: this.fileData,
        message_attachment_type: extension,
        message_attachment_name: this.nameFileData,
        image_attachment: `data:image/${extension};base64, ` + this.fileData,
        message_attachment_size:
          this.nameFileData != null ? Math.round(size).toString() : null,
        datetime: date_text + " " + hour_text,
      }

      if (this.messageType === "image") {
        var { image_attachment, message_attachment } = newMessage
        this.addImage(image_attachment ? image_attachment : message_attachment)
        this.countImage++
      }

      this.messages.push(newMessage)
      // change content in users
      this.users.map((user) => {
        if (
          String(user.user).toUpperCase() ==
          String(this.client.user).toUpperCase()
        ) {
          user.content = this.message
          user.created_at = new Date()
        }
      })
      this.clearContent()
      this.removePreloader()
      await ChatService.sendMessage(newMessage)
    },
    receiveMessage(
      content,
      advisor_id,
      user,
      message_type,
      message_attachment,
      message_attachment_name,
      message_attachment_type
    ) {
      if (
        String(user).toUpperCase() == String(this.client.user).toUpperCase()
      ) {
        var date_text = moment().format("MM/DD/YYYY")
        var hour_text = new Date().toLocaleTimeString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "America/Los_Angeles",
        })
        var new_id = this.messages.length + 1
        var newMessage = {
          id: new_id,
          user: user,
          name_user: this.clientName,
          content: content,
          advisor_id: advisor_id, // this.currentuser.id,
          is_sender: true,
          message_type: message_type,
          indexImage: message_type === "image" ? this.countImage : null,
          message_attachment: message_attachment,
          message_attachment_type: message_attachment_type,
          message_attachment_name: message_attachment_name,
          created_at: date_text + " " + hour_text,
          hour: moment(date_text + " " + hour_text).format("H:mm"),
          date: moment(date_text + " " + hour_text).format("MM/DD/YYYY"),
        }
        if (newMessage.message_type === "image") {
          this.addImage(message_attachment)
          this.countImage++
        }
        this.messages.push(newMessage)
        this.users.map((_user) => {
          if (String(_user).toUpperCase() == String(user).toUpperCase()) {
            user.content = content
            user.created_at = new Date()
          }
        })
        this.clearContent()
      }
    },

    addImage(image) {
      this.itemImage.push(image)
    },

    clearContent() {
      this.message = null
      this.clearFile()
      this.$nextTick(function () {
        this.scrollToEnd()
      })
    },
    scrollToEnd() {
      var container = this.$el.querySelector("#chat")
      if (container) {
        container.scrollTop = container.scrollHeight
      }
    },
    validateIsImage(nameFile) {
      var extension = nameFile.split(".").pop()
      if (extension == "png" || extension == "jpg" || extension == "jpeg") {
        return "image"
      } else {
        return "file"
      }
    },
    toogleRightBar() {
      this.showRightBar = !this.showRightBar
    },
  },
  async created() {
    await this.getUsers()
    if (this.$route.params?.account) {
      this.users.forEach((item) => {
        if (item.user == this.$route.params?.account) {
          this.setClient(item)
        }
      })
    }
  },
}
</script>

<style scoped lang="scss">
.hover-user {
  &:hover {
    background: #323336;
    border-radius: 0 0.5rem 0.5rem 0;
    &.light {
      background: #f0efef;
    }
  }
}
.head {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  min-height: 6rem;
}

.head--2col {
  grid-template-columns: 1fr 4fr;
}

.head__box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  border-left: 2px solid #3e4143;
  &.light {
    border-left: 2px solid #f0efef;
  }
}

.head__dot {
  position: absolute;
  top: 0.5rem;
  right: -1rem;
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 1rem;
  background: green;
  border-radius: 50%;
}

.body {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  height: 75vh;
}

.body--2col {
  grid-template-columns: 1fr 4fr;
}

.body__box {
  height: 75vh;
  padding: 1rem;
  border-top: 2px solid #3e4143;
  border-left: 2px solid #3e4143;
  height: 75vh;
  &.light {
    border-top: 2px solid #dadada;
    border-left: 2px solid #dadada;
  }
}

.body__users {
  // display: grid;
  // grid-template-columns: 1fr;
  // gap: 2rem;
  overflow-y: auto;
}

.user {
  display: flex;
  align-items: start;
  gap: 1rem;
  padding: 0.2rem;
}

.user--active {
  background: #3e4143;
  border-radius: 0 0.5rem 0.5rem 0;
  border-left: 4px solid #0090e7;
  &.light {
    background: #dadada;
    border-left: 4px solid #0090e7;
  }
}

.body__files {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  max-height: 46rem;
  overflow-y: auto;
}

.file {
  display: block;
  width: 6rem;
  height: 6rem;
}

.body__chat {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
}

.body__messages {
  flex-grow: 1;
  overflow-y: auto;
  height: 40rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.message {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.message--mine {
  flex-direction: row-reverse;
}

.message__content {
  display: flex;
  flex-direction: column;
}

.message__content--mine {
  align-items: flex-end;
}

.message__text {
  max-width: 60rem;
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 1rem;
  background: #454545;
  color: #fff;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
}

.message__text--mine {
  background: #516091;
  border-radius: 0.5rem 0 0.5rem 0.5rem;
}

.message__text--user {
  background: #74bec1;
  border-radius: 0.5rem 0 0.5rem 0.5rem;
}

.message__time {
  color: gray;
}

.body__input {
  display: flex;
  gap: 1rem;
}

.input {
  flex-grow: 1;
  display: flex;
  padding: 0.5rem 1rem;
  background: #393b40;
  border-radius: 2rem;
}

.input__field {
  flex-grow: 1;
  background: none;
  border: none;
  outline: none;
  color: #fff;
}
//
</style>
