<template>
  <div class="conversation-area">
    <div
      class="msg online"
      v-for="(item, index) in users"
      :key="index"
      :class="active == index ? 'active' : ''"
      @click="
        active = index;
        getMessages(item);
      "
    >
      <img
        class="msg-profile"
        :src="`/assets/images/icons/user-app${isDarkSkin ? '-w' : ''}.png`"
        alt=""
      />
      <div class="msg-detail">
        <div class="msg-username">{{ item.name_user }}</div>
        <div class="msg-content">
          <span class="msg-message">{{ item.content }}</span>
          <span class="msg-date">20m</span>
        </div>
      </div>
    </div>
    <div class="overlay"></div>
  </div>
</template>
<script>
import chatService from "@/views/ce-digital/sub-modules/customer-service/views/chat-app/services/chat-app.service";
import mixin from "@/views/ce-digital/sub-modules/customer-service/views/chat-app/mixin.js";
export default {
  mixins: [mixin],
  data() {
    return {
      users: [],
      active: null,
      item: {},
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    async getUsers() {
      this.addPreloader();
      try {
        const params = { advisor_id: null };
        const data = await chatService.getUsersApp(params);
        this.users = data;
        this.removePreloader();
      } catch (e) {
        console.log(e);
        this.removePreloader();
      }
    },
    async getMessages(item) {
      this.addPreloader();
      this.item = item;
      try {
        const params = { advisor_id: null, user: item.user };
        const response = await chatService.getMessages(params);
        let messages = [...response.messages];
        let files = this.getFiles(messages);
        let data = this.groupByDate(messages);
        this.removePreloader();
        this.$emit("setMessages", response.messages, data, files, this.item);
      } catch (e) {
        console.log(e);
        this.removePreloader();
      }
    },

    getFiles(messages) {
      let data = [];
      messages.forEach((element) => {
        if (element.message_type != "text") {
          data.push(element);
        }
      });
      return data;
    },
  },
};
</script>